<template>
  <div>
    <div flex-row-flex-start-flex-start class="tag-spacing">
      <span class="component-label">户籍所在省（市）：</span>
      <div flex-row-flex-start-center>
        {{ nativeComputed }}
      </div>
    </div>

    <div flex-row-flex-start-flex-start class="tag-spacing">
      <span class="component-label">证件号码：</span>
      <div flex-row-flex-start-center>
        {{ answerJsonValue.cardNumber }}
      </div>
    </div>

    <div flex-row-flex-start-flex-start class="tag-spacing">
      <span class="component-label">出生日期：</span>
      <div flex-row-flex-start-center>
        {{ answerJsonValue.birthday }}
      </div>
    </div>

    <div flex-row-flex-start-flex-start class="tag-spacing" v-if="answerJsonValue.cardFrontImg || answerJsonValue.cardReverseImg">
      <span class="component-label">证件照：</span>
      <div flex-row-flex-start-center>
        <el-image class="item-of-image" style="height: 200px;" :src="normalizeImageUrl(answerJsonValue.cardFrontImg)" alt="" :preview-src-list="[normalizeImageUrl(answerJsonValue.cardFrontImg)]"></el-image>
        <el-image class="item-of-image" style="height: 200px;" :src="normalizeImageUrl(answerJsonValue.cardReverseImg)" alt="" :preview-src-list="[normalizeImageUrl(answerJsonValue.cardReverseImg)]"></el-image>
      </div>
    </div>

    <div flex-row-flex-start-flex-start class="tag-spacing" v-if="answerJsonValue.residentialImg">
      <span class="component-label">工作居住证：</span>
      <div flex-row-flex-start-center>
        <el-image class="item-of-image" style="height: 200px;" :src="normalizeImageUrl(answerJsonValue.residentialImg)" alt="" :preview-src-list="[normalizeImageUrl(answerJsonValue.residentialImg)]"></el-image>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">

export default {
  inject: {
    ZtCountryAdvance: { from: 'ZtCountryAdvance' },
  },
  // extends   : {},
  mixins    : [],
  components: {},
  name      : 'MainlandUserComponent',
  props     : {
    className: { type: String, default: '' },
  },
  data() {
    return {}
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    nativeComputed() {
      return this.ZtCountryAdvance.nativeComputed
    },
    answerJsonValue() {
      return this.ZtCountryAdvance.answerJsonValue
    },
  },
  methods : {
    //
  },
  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},
  mounted() {},
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "../component.scss";
</style>
